import React, { useEffect, useRef, useState } from "react";
import "./editStyle.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Chip from "@mui/material/Chip";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CONSTANT from "../../../constant/Option";
import ConfirmDialog from "../../../Component/DeletedConfirmatin/DeleteDailogue";
import { useParams } from "react-router-dom";
import Order from "../../../services/order.service";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "../../../../src/Component/redux/astroSlice";
import InputLabel from "@mui/material/InputLabel";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import User from "../../../services/user.service";
import OPTIONS from "../../../constant/Option";
// import ConfirmDialog from "../../../Component/DeletedConfirmatin/DeleteDailogue";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OrderDetails = () => {
  const [status, setStatus] = React.useState(null);
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [pop, setPop] = React.useState(false);
  const [deliveryDetail, SetDeliveryDetail] = React.useState({});
  const dispatch = useDispatch();
  var { id } = useParams();
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const fileInputRef = useRef(null);
  const [date, setDate] = useState("");
  const currentUser = useSelector((state) => state.currentUser);
  const [comfirmSubAdmin, setComfirmSubAdmin] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Replace this URL with your API endpoint to fetch users
      const response = await User.getAllUser({ role: ["SUB_ADMIN"] });
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch users when the autocomplete is opened
  const handleOpen = () => {
    setOpen(true);
    setOptions([]);
    fetchUsers(); // Fetch users only if options list is empty
  };

  async function fetchData() {
    const data = await Order.getById(id);
    console.log("**DATa**",data)
    console.log("**order", data.data[0]);
    setOrder(data.data[0]);
    const dateFromMongoDB = new Date(data.data[0]?.createdAt);
    const formattedDate = new Date(dateFromMongoDB).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // Set to India Standard Time (IST)
    });

    setDate(formattedDate);
  }

  const updateStatus = async () => {
    try {
      setLoading(true);
      const response = await Order.changeStatus(
        { status: status, deliveryDetail },
        id
      );
      setPop(false);
      setLoading(false);
    } catch (error) {
      setPop(false);
      setLoading(false);
    }
  };

  const AssignAccess = async () => {
    try {
      setLoading(true);
      const response = await Order.update({ manageBy: selectedUserId }, id);
      setComfirmSubAdmin(false);
      setLoading(false);
    } catch (error) {
      setPop(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Status Got change", status);
    if (status) {
      if (status == CONSTANT.defaultOrderStatus.DISPATCH) {
        dispatch(
          openForm({
            fields: [
              { name: "name", label: "Name" },
              { name: "vehicleNumber", label: "Vehicle Number", type: "text" },
              { name: "phoneNumber", label: "Phone Number", type: "number" },
            ],
            onSubmit: (formData) => {
              console.log("Form Data:", formData);
              SetDeliveryDetail(formData);
              setPop(true);
            },
            formInfo: {
              title: "Please Enter Delivery Details",
            },
          })
        );
      } else {
        setPop(true);
      }
    }
  }, [status]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ConfirmDialog
        open={pop}
        onConfirm={updateStatus}
        onClose={() => setPop(false)}
        isloading={loading}
        title={"Confirmation to change Status"}
        content={`Status will Updated to ${status}`}
      />

      <ConfirmDialog
        open={comfirmSubAdmin}
        onConfirm={AssignAccess}
        onClose={() => setComfirmSubAdmin(false)}
        isloading={loading}
        title={"Confirmation To Assign SubAdmin"}
        content={`All the access to Particular order will be given to this Admin`}
      />
      <div className="row w-100 p-0 m-0 p-2">
        <div className="col-12 m-0 p-0">
          <div className="row m-0 my-1 w-100 d-flex justify-content-between">
            <div
              className="col-12  col-sm-4 my-3 my-sm-0 p-0 m-0"
              style={{ overflowY: "hidden" }}
            >
              <div className="row w-100  p-0 m-0 ">
                <div className="col-12 p-0 m-0">
                  <Typography
                    variant="h"
                    fontSize={25}
                    style={{ fontWeight: "bold" }}
                  >
                    Order
                  </Typography>
                  <Chip
                    color={CONSTANT.defaultOrderStatus.color[order?.status]}
                    sx={{ minWidth: "77px", marginLeft: "5px" }}
                    label={order?.status}
                  />
                </div>
                <div className="col-12 p-0 m-0">
                  <Typography variant="caption" fontSize={15}>
                    {date}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-md-8  m-0 my-2 ">
              <div className="row   my-2   ">
                <div className=" col-md-5 col-12 p-0 mx-sm-2 mx-0 my-2">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Change Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={ formData.measureUnit || ''}
                      label="Measure Unit"
                      name="measureUnit"
                      onChange={handleChange}
                      status={status}
                      value={status}
                    >
                      {CONSTANT.defaultOrderStatus
                        .getStatusOfOrderByFilter(currentUser.role)
                        .map((status) => {
                          return <MenuItem value={status}>{status}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className=" col-md-5 col-12 p-0 mx-sm-2 mx-0 my-2 ">
                  {OPTIONS.usersRoles
                    .onlyAdminAccess()
                    .includes(currentUser.role) ? (
                    <>
                      {" "}
                      <Autocomplete
                        open={open}
                        onOpen={handleOpen}
                        onClose={() => setOpen(false)}
                        getOptionLabel={(option) =>
                          option.firstName.toUpperCase() + "  " + option.email
                        } // Display user's name in the dropdown
                        options={options}
                        loading={loading}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedUserId(newValue._id); // Store the selected user's ID
                            setComfirmSubAdmin(true);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assign sub Admin"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-sm-12  col-12 col-md-10      m-0   mx-2 mx-auto  rounded  HoriScroll global"
          style={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <h5 className="p-0 ms-2">Product Detail</h5>
          <div className="container">
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead className="">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" style={{ minWidth: "350px" }}>
                      Description
                    </th>
                    <th scope="col" className="text-end ">
                      Qty
                    </th>
                    <th scope="col" className="text-end ">
                      Unit_price
                    </th>
                    <th scope="col" className="text-end ">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order.products?.map((product, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <strong>{product.productInfo.title}</strong>
                          <br />
                          <small>{product.productInfo.description}</small>
                        </td>
                        <td className="text-end">{product.quantity}</td>
                        <td className="text-end">${product.unitPrice}</td>
                        <td className="text-end">${product.totalPrice}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Subtotal
                    </td>
                    <td className="text-end">${order?.subTotal}</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Shipping
                    </td>
                    <td className="text-end text-danger">
                      - ${order?.shipping}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Discount
                    </td>
                    <td className="text-end text-danger">
                      - ${order?.discount}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      Taxes
                    </td>
                    <td className="text-end">${order?.taxes}</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-end">
                      <strong>Total</strong>
                    </td>
                    <td className="text-end">
                      <strong>${order?.grandTotal}</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="row w-100 m-0 px-md-3 px-1 p-0 d-flex justify-content-between">
          <div
            className="col-md-5 col-12 p-0  m-0 d-flex justify-content-center"
            id="uploadSection"
          >
            <div className="row w-100 p-0 m-0 " style={{ width: "98%" }}>
              <div
                className="col-12 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
                style={{
                  maxHeight: "300px",
                  minHeight: "300px",
                  boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <Typography
                  variant="body"
                  sx={{ width: "100%", marginLeft: 1 }}
                  color="initial"
                >
                  Customer Details
                </Typography>
                <div
                  className="rounded-circle border d-flex justify-content-center align-items-center"
                  style={{ minWidth: "150px", minHeight: "150px" }}
                >
                  {true ? (
                    <img
                      src={order?.customerInfo?.imageUrl}
                      className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                      style={{
                        minWidth: "130px",
                        maxWidth: "130px",
                        minHeight: "130px",
                        maxHeight: "130px",
                      }}
                    />
                  ) : (
                    <div
                      className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                      style={{
                        minWidth: "130px",
                        minHeight: "130px",
                        background: "lightgrey",
                      }}
                    >
                      <CameraAltIcon></CameraAltIcon>
                      <span>upload photo</span>
                    </div>
                  )}
                </div>
                <div className="row  w-100 p-0 my-2">
                  <div className="col-12  p-0 my-1 ">
                    Name :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.customerInfo?.firstName +
                        " " +
                        order?.customerInfo?.lastName}
                    </Typography>
                  </div>
                  <div className="col-12 p-0 my-1">
                    Email :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.customerInfo?.email}
                    </Typography>
                  </div>
                  <div className="col-12 border p-0 my-1">
                    Contact :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.customerInfo?.phoneNumber}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <input type="file" ref={fileInputRef} style={{ display: "none" }} />
          </div>

          <div
            className="col-md-5 col-12 p-0  d-flex justify-content-center"
            id="uploadSection"
          >
            <div className="row w-100 p-0 m-0 " style={{ width: "98%" }}>
              <div
                className="col-12 my-3 mx-sm-2 mx-auto rounded   d-flex justify-content-center align-items-center flex-column"
                style={{
                  maxHeight: "300px",
                  minHeight: "300px",
                  boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <Typography
                  variant="body"
                  sx={{ width: "100%", marginLeft: 1 }}
                  color="initial"
                >
                  Additional Details
                </Typography>
                <div
                  className="rounded-circle border d-flex justify-content-center align-items-center"
                  style={{ minWidth: "150px", minHeight: "150px" }}
                >
                  {true ? (
                    <img
                      src={order?.manageByInfo?.imageUrl}
                      className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                      style={{
                        minWidth: "130px",
                        maxWidth: "130px",
                        minHeight: "130px",
                        maxHeight: "130px",
                      }}
                    />
                  ) : (
                    <div
                      className="rounded-circle border  d-flex justify-content-center align-items-center  flex-column"
                      style={{
                        minWidth: "130px",
                        minHeight: "130px",
                        background: "lightgrey",
                      }}
                    >
                      <CameraAltIcon></CameraAltIcon>
                      <span>upload photo</span>
                    </div>
                  )}
                </div>
                <div className="row  w-100 p-0 my-2">
                  <div className="col-12  p-0 my-1 ">
                    Managed By :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                     {order?.manageByInfo?.firstName
      ? `${order?.manageByInfo?.firstName} ${order?.manageByInfo?.lastName || ""}`
      : "Not Assigned"}
                    </Typography>
                  </div>
                  <div className="col-12 p-0 my-1">
                    DeliveryBy :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.deliveryDetail?.name}
                    </Typography>
                  </div>
                  <div className="col-12 p-0 my-1">
                    Vehicle NO :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.deliveryDetail?.vehicleNumber}
                    </Typography>
                  </div>
                  <div className="col-12 border p-0 my-1">
                    Contact :
                    <Typography
                      variant="body1"
                      sx={{ display: "inline" }}
                      color="initial"
                    >
                      {" "}
                      {order?.deliveryDetail?.phoneNumber}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <input type="file" ref={fileInputRef} style={{ display: "none" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const getStatusColor = (status) => {
  switch (status) {
    case "PROCESSING":
      return "green";
    case "CANCEL":
      return "grey";
    case "RETURNED":
      return "orange";
    default:
      return "lightblue";
  }
};

const CustomSelect = styled(Select)(({ theme, status }) => ({
  color: "black",
  backgroundColor: "white",
}));
