import React from "react";
import "./mapview.css";
import { Fragment, useEffect, useState } from "react";
import Visit from "../../../services/visit.service";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";


import CONSTANT from "../../../constant/Option";


import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";

const markers = [
  {
    id: 1,
    name: "Amit",
    position: { lat: 21.171281, lng: 79.134078 },
  },
  {
    id: 2,
    name: "opendra",
    position: { lat: 21.171945, lng: 79.1340871 },
  },
  {
    id: 3,
    name: "Anand",
    position: { lat: 21.1798439, lng: 79.1351881 },
  },
];



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Create() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDriXORK00W-RReAC-vGwoDt-2-e0EaoXg",
  });

  
  const [activeMarker, setActiveMarker] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [filter, setFilter] = useState({
    startDate: dayjs().subtract(1, 'day'), // One day before today
  endDate: dayjs().add(1, 'day'), 
    typeOfVisit:[]
    
  });
  const [personName, setPersonName] = React.useState([]);


  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // filter.role(typeof value === "string" ? value.split(",") : value)
    setFilter({...filter,typeOfVisit:typeof value === "string" ? value.split(",") : value})
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };



  const fetchMarkers=async()=>{
  
      try {
        const data = await Visit.getAllForMap(filter);
        console.log("got the data from api",data.data);
        setMarkers(data.data); // Update state with fetched marker data
      } catch (error) {
        console.error("Error fetching marker data:", error);
      }
  
  }



  useEffect(() => {
    // Fetch marker data from your API
   

    fetchMarkers();
  }, []);

  useEffect(()=>{
fetchMarkers();

  },[filter])



  const infoWindowStyle = {
    padding: "10px",
    maxWidth: "200px",
    textAlign: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
  };

  const imageStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "10px",
  };

  const headerStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "5px 0",
  };

  const typeOfVisitStyle = {
    fontSize: "14px",
    color: "#555",
  };

  const timestampStyle = {
    fontSize: "12px",
    color: "blue", // Gray color for subtlety
    marginTop: "5px",
  };

  return (
    <Fragment>
      <div
        className="container"
        style={{ overflow: "visible" }}
        id="map-container"
      >
        <div className="row my-2 m-0 p-0">
          <div className="col-6 col-md-3  my-2 my-sm-2 ps-1 p-0 ">
          <FormControl sx={{ minWidth:"100%", maxWidth:"100%"} } >
                <InputLabel id="demo-multiple-checkbox-label">Visit Type</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filter.typeOfVisit}
                  onChange={handleChange2}
                  input={<OutlinedInput  label="Status" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}               
                >
                  {CONSTANT.visitType.getAllVisitAsArray().map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={filter.typeOfVisit.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

          </div>
          <div className="col-6 col-md-3  my-2 my-sm-2  px-2 p-0">
          <OutlinedInput
                value={filter.search}
                onChange={e=>{setFilter({...filter,search:e.target.value})}}

                color="primary"
                placeholder="Search..."
                sx={{ width: "100%", height: "55px", }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />

          </div>
          <div className="col-6 col-md-3 my-2 my-sm-2 ps-1 p-0">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start"
                sx={{width:"100%"}}
                value={filter.startDate}
                onChange={(newValue) =>
                  setFilter({ ...filter, startDate: newValue })      
                }        
              />
            </LocalizationProvider>
          </div>
          <div className="col-6 col-md-3  my-2 my-sm-2 px-2 p-0">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End"
                sx={{width:"100%"}}
                value={filter.endDate}
                onChange={(newValue) =>
                  setFilter({ ...filter, endDate: newValue })
                }
              />
            </LocalizationProvider>
          </div>
        </div>
        <div style={{ height: "75vh", width: "100%" }}>
          {isLoaded ? (
            <GoogleMap
              center={{ lat: 21.1717469, lng: 79.1340581 }}
              zoom={18}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "90vh" }}
            >
              {markers?.map(
                ({ _id, employeeInfo, position, typeOfVisit, createdAt }) => {
                  // Ensure latitude and longitude are numbers
                 
                  const isValidPosition =
                    !isNaN(position.lat) && !isNaN(position.lng);

                  return isValidPosition ? (
                    <MarkerF
                      key={_id}
                      position={position}
                      onClick={() => handleActiveMarker(_id)}
                      title="I am marker"
                      zIndex={1}
                      draggable={false}
                      icon={{
                        url: employeeInfo.imageUrl
                          ? employeeInfo.imageUrl
                          : "https://res.cloudinary.com/dvyt63pnb/image/upload/v1727154633/q2ykz6vkcc7qqmgr4a5i.png",
                        scaledSize: { width: 50, height: 50 },
                      }}
                      // label={{
                      //   text: "Marker Label", // The text for the label
                      //   color: "red", // Text color
                      //   fontSize: "14px", // Font size
                      //   fontWeight: "bold",
                      // }}
                    >
                      {activeMarker === _id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div style={infoWindowStyle}>
                            <img
                              src={employeeInfo?.imageUrl?employeeInfo.imageUrl:"https://res.cloudinary.com/dvyt63pnb/image/upload/v1727154633/q2ykz6vkcc7qqmgr4a5i.png"}
                              alt={`${employeeInfo?.firstName}'s profile`}
                              style={imageStyle}
                            />
                            <h4 style={headerStyle}>
                              {employeeInfo?.firstName}
                            </h4>
                            <p style={typeOfVisitStyle}>{typeOfVisit}</p>
                            <p style={timestampStyle}>
                              Active at: {new Date(createdAt).toLocaleString()}
                            </p>
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </MarkerF>
                  ) : null;
                }
              )}
            </GoogleMap>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default Create;
