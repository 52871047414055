// import { toast } from "react-toastify";
// import axiosInstance from "../utils/requestInstance";
// import environment from "../enviroment";
// import apiRequest from "../Component/customHool/axiosRequest";

// const InVoice = {
//   async getAll(payload) {
//     try {
//       const response = await axiosInstance.get(`/invoice/getAll`, {
//         params: payload,
//       });
//       let data = response.data.result;
//       return data;
//     } catch (error) {
//       console.log(error);
//     }
//   },

//   async Create(payload) {
//     try {
//       const response = await axiosInstance.post(`/invoice/create`, payload);
//       const data=response.data
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   },

//   async getById(payload) {
//     try {
//       const response = await axiosInstance.get(`/invoice/getById/${payload}`);
//       return response.data.result;
//     } catch (error) {
//       console.log(error);
//     }
//   },

//   async updateById(payload, id) {
//     try {
//       const response = await axiosInstance.put(
//         `/invoice/update/${id}`,
//         payload
//       );

//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   },


//   async delete(payload, id) {
//     try {
//       const response = await axiosInstance.delete(
//         `/invoice/delete/${id}`,
//       );

//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   },




// };

// export default InVoice;
import apiRequest from "../Component/customHool/axiosRequest";

const Invoice = {
  async getAll(params) {
    return apiRequest('GET', '/invoice/getAll', {}, params);
  },

  async Create(payload) {
    return apiRequest('POST', '/invoice/create', payload, {});
  },

  async getById(id) {
    return apiRequest('GET', `/invoice/getById/${id}`, {}, {});
  },

  async updateById(payload, id) {
    return apiRequest('PUT', `/invoice/update/${id}`, payload, {});
  },

  async delete(id) {
    return apiRequest('DELETE', `/invoice/delete/${id}`, {}, {});
  }
};

export default Invoice;
