const OPTIONS = {
  defaultPageSize: {
    pageSize: 5,
    one: 5,
    two: 10,
    three: 25,
    four: 50,
    five: 100,
    getAllDefaultPageSizeOfUserAsArray: function () {
      return [
        OPTIONS.defaultPageSize.one,
        OPTIONS.defaultPageSize.two,
        OPTIONS.defaultPageSize.three,
        OPTIONS.defaultPageSize.four,
        OPTIONS.defaultPageSize.five,
      ];
    },
  },

  visitType: {
    NEW: "NEW",
    COLLECTION: "COLLECTION",
    ORDER: "ORDER",
    getAllVisitAsArray: function () {
      return [
        OPTIONS.visitType.NEW,
        OPTIONS.visitType.COLLECTION,
        OPTIONS.visitType.ORDER,
      ];
    },
  },

  locationRegion: {
    NORTH: "NORTH",
    SOUTH: "SOUTH",
    EAST: "EAST",
    WEST: "WEST",
    getAllLocationRegion: () => {
      return [
        OPTIONS.locationRegion.NORTH,
        OPTIONS.locationRegion.SOUTH,
        OPTIONS.locationRegion.EAST,
        OPTIONS.locationRegion.WEST,
      ];
    },
  },

  usersRoles: {
    ADMIN: "ADMIN",
    CandF: "C&F",
    AREA_SALE_MANAGER: "AREA_SALE_MANAGER",
    SUB_ADMIN: "SUB_ADMIN",
    EMPLOYEE: "EMPLOYEE",
    ZONAL_SALES_MANAGER: "ZONAL_SALES_MANAGER",
    REGIONAL_SALES_MANAGER: "REGIONAL_SALES_MANAGER",
    SALES_OFFICER: "SALES_OFFICER",
    STOKIEST: "STOKIEST",
    RETAILER: "RETAILER",
    DEALER: "DEALER",

    getAllRolesOfUserAsArray: function () {
      return [
        OPTIONS.usersRoles.ADMIN,
        OPTIONS.usersRoles.AREA_SALE_MANAGER,
        OPTIONS.usersRoles.CandF,
        OPTIONS.usersRoles.DEALER,
        OPTIONS.usersRoles.EMPLOYEE,
        OPTIONS.usersRoles.REGIONAL_SALES_MANAGER,
        OPTIONS.usersRoles.RETAILER,
        OPTIONS.usersRoles.SALES_OFFICER,
        OPTIONS.usersRoles.SUB_ADMIN,
        OPTIONS.usersRoles.STOKIEST,
        OPTIONS.usersRoles.ZONAL_SALES_MANAGER,
      ];
    },

    onlyAdminAccess: function () {
      return [OPTIONS.usersRoles.ADMIN];
    },

    onlySubAdminAccess: function () {
      return [OPTIONS.usersRoles.ADMIN,OPTIONS.usersRoles.SUB_ADMIN];
    },

    onlyEmployeeAccess: function () {
      return [
        OPTIONS.usersRoles.ADMIN,
        OPTIONS.usersRoles.SUB_ADMIN,
        OPTIONS.usersRoles.EMPLOYEE,
        OPTIONS.usersRoles.ZONAL_SALES_MANAGER,
        OPTIONS.usersRoles.SALES_OFFICER,
        OPTIONS.usersRoles.CandF,
        OPTIONS.usersRoles.REGIONAL_SALES_MANAGER,
        OPTIONS.usersRoles.AREA_SALE_MANAGER,
      ];
    },

    onlyCustomerLevelAccess:function () {
      return [
        OPTIONS.usersRoles.RETAILER,
            OPTIONS.usersRoles.DEALER,
            OPTIONS.usersRoles.STOKIEST,
            OPTIONS.usersRoles.CandF
      ];
    },


    getRolesOfUserByFilter: function (role) {
      console.log("filter role called", role);
      const allRoles = [
        OPTIONS.usersRoles.ADMIN,
        OPTIONS.usersRoles.CandF,
        OPTIONS.usersRoles.DEALER,
        OPTIONS.usersRoles.EMPLOYEE,
        OPTIONS.usersRoles.REGIONAL_SALES_MANAGER,
        OPTIONS.usersRoles.RETAILER,
        OPTIONS.usersRoles.SALES_OFFICER,
        OPTIONS.usersRoles.STOKIEST,
        OPTIONS.usersRoles.SUB_ADMIN,
        OPTIONS.usersRoles.ZONAL_SALES_MANAGER,
      ];

      switch (role) {
        case OPTIONS.usersRoles.ADMIN:
          // Return all roles for ADMIN
          return this.getAllRolesOfUserAsArray();

        case OPTIONS.usersRoles.SUB_ADMIN:
          // Return all roles except ADMIN for SUB_ADMIN
          return allRoles.filter(
            (r) =>
              r !== OPTIONS.usersRoles.ADMIN &&
              r !== OPTIONS.usersRoles.SUB_ADMIN
          );

        case OPTIONS.usersRoles.EMPLOYEE:
        case OPTIONS.usersRoles.AREA_SALE_MANAGER:
        case OPTIONS.usersRoles.ZONAL_SALES_MANAGER:
        case OPTIONS.usersRoles.REGIONAL_SALES_MANAGER:
        case OPTIONS.usersRoles.SALES_OFFICER:
       
          // Return only DEALER and RETAILER for EMPLOYEE
          return [
            OPTIONS.usersRoles.RETAILER,
            OPTIONS.usersRoles.DEALER,
            OPTIONS.usersRoles.STOKIEST,
            OPTIONS.usersRoles.CandF,
          ];

        case OPTIONS.usersRoles.DEALER:
          // Return only DEALER for DEALER
          return [];

        case OPTIONS.usersRoles.ZONAL_SALES_MANAGER:
          // Return only DEALER for DEALER
          return [];

        default:
          // Return an empty array if the role doesn't match any case
          return [];
      }
    },
    getADMIN: () => [OPTIONS.usersRoles.ADMIN, OPTIONS.usersRoles.SUB_ADMIN],
    getEMPLOYEE: () => [OPTIONS.usersRoles.EMPLOYEE],
    getDEALER: () => [OPTIONS.usersRoles.SHOP, OPTIONS.usersRoles.DEALER],
    getRETAILER: () => [OPTIONS.usersRoles.SHOP, OPTIONS.usersRoles.RETAILER],
  },
  genders: {
    MALE: "MALE",
    FEMALE: "FEMALE",
    TRANSGENDER: "TRANSGENDER",
    getAllGenderAsArray: function () {
      return [
        OPTIONS.genders.MALE,
        OPTIONS.genders.FEMALE,
        OPTIONS.genders.TRANSGENDER,
      ];
    },
  },

  defaultImage: {
    user: "",
    product:
      "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg",
  },

  defaultStatusOFUser: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    DELETED: "DELETED",
    BLACKLISTED: "BLACKLISTED",
    getDefaultStatusOFUserasArray: function () {
      return [
        OPTIONS.defaultStatusOFUser.ACTIVE,
        OPTIONS.defaultStatusOFUser.INACTIVE,
        OPTIONS.defaultStatusOFUser.DELETED,
        OPTIONS.defaultStatusOFUser.BLACKLISTED,
      ];
    },

    getAllStatusOfUser: function () {
      return [
        OPTIONS.defaultStatusOFUser.ACTIVE,
        OPTIONS.defaultStatusOFUser.INACTIVE,
        OPTIONS.defaultStatusOFUser.BLACKLISTED,
      ];
    },
  },
  defaultOrderStatus: {
    ACTIVE: "ACTIVE",
    ACCEPTED: "ACCEPTED",
    DISPATCH: "DISPATCH",
    DELIVERED: "DELIVERED",
    CANCEL: "CANCEL",
    RETURN: "RETURN",
    PROCESSING: "PROCESSING",
    REJECTED: "REJECTED",
    color: {
      ACTIVE: "primary",
      ACCEPTED: "primary",
      DISPATCH: "secondary",
      DELIVERED: "success",
      CANCEL: "warning",
      RETURN: "error",
      PROCESSING: "info",
      REJECTED: "error",
    },

    getAllOrderStatusAsArray: function () {
      return [
        OPTIONS.defaultOrderStatus.DISPATCH,
        OPTIONS.defaultOrderStatus.ACTIVE,
        OPTIONS.defaultOrderStatus.DELIVERED,
        OPTIONS.defaultOrderStatus.CANCEL,
        OPTIONS.defaultOrderStatus.RETURN,
        OPTIONS.defaultOrderStatus.ACCEPTED,
        OPTIONS.defaultOrderStatus.REJECTED,
        OPTIONS.defaultOrderStatus.PROCESSING,
      ];
    },

    getStatusOfOrderByFilter: function (role) {
      console.log("filter status called", role);

      switch (role) {
        case OPTIONS.usersRoles.ADMIN:
          // Return all roles for ADMIN
          return this.getAllOrderStatusAsArray();

        case OPTIONS.usersRoles.SUB_ADMIN:
          // Return all roles except ADMIN for SUB_ADMIN
          return this.getAllOrderStatusAsArray();

        case OPTIONS.usersRoles.EMPLOYEE:
          // Return only DEALER and RETAILER for EMPLOYEE
          return [this.DELIVERED];

        case OPTIONS.usersRoles.DEALER:
          // Return only DEALER for DEALER
          return [];

        case OPTIONS.usersRoles.ZONAL_SALES_MANAGER:
          // Return only DEALER for DEALER
          return [];

        default:
          // Return an empty array if the role doesn't match any case
          return [];
      }
    },
  },

  defaultMeasureUnit: {
    KG: "KG",
    GRAM: "GRAM",
    LITER: "LITER",
    ML: "ML",
    PIECE: "PIECE",
    METER: "METER",
    getAllDefaultMeasureUnitasArray: function () {
      return [
        OPTIONS.defaultMeasureUnit.KG,
        OPTIONS.defaultMeasureUnit.GRAM,
        OPTIONS.defaultMeasureUnit.LITER,
        OPTIONS.defaultMeasureUnit.PIECE,
        OPTIONS.defaultMeasureUnit.METER,
        OPTIONS.defaultMeasureUnit.ML,
      ];
    },
  },

  defaultPaymentType: {
    CASH: "CASH",
    CHECK: "CHEQUE",
    UPI: "UPI",
    getAllDdefaultPaymentTypeasArray: function () {
      return [
        OPTIONS.defaultPaymentType.CASH,
        OPTIONS.defaultPaymentType.CHECK,
        OPTIONS.defaultPaymentType.UPI,
      ];
    },
  },

  defaultStatusOFUser: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    DELETED: "DELETED",
    BLACKLISTED: "BLACKLISTED",
    getDefaultStatusOFUserasArray: function () {
      return [
        OPTIONS.defaultStatusOFUser.ACTIVE,
        OPTIONS.defaultStatusOFUser.INACTIVE,
        OPTIONS.defaultStatusOFUser.DELETED,
        OPTIONS.defaultStatusOFUser.BLACKLISTED,
      ];
    },
  },

  defaultPaymentGreatherThan: {
    1: 500,
    2: 1000,
    3: 2000,
    4: 5000,
    getAllDdefaultPaymentTypeasArray: function () {
      return [
        OPTIONS.defaultPaymentGreatherThan["1"],
        OPTIONS.defaultPaymentGreatherThan["2"],
        OPTIONS.defaultPaymentGreatherThan["3"],
        OPTIONS.defaultPaymentGreatherThan["4"],
      ];
    },
  },

  onlineStatus: {
    AVAILABLE: "available",
    UN_AVAILABLE: "un_available",
  },
  notificationTitle: {
    NEW_ORDER: `New Order !!`,
    NEW_ORDER_RECEIVED: `New order received !!`,
    STATUS_ORDER: (status) => `Order ${status} !!`,
    NEW_PRODUCT_REQUEST: `New product request !!`,
    STATUS_PRODUCT_REQUEST: "Product request update !!",
    CHAT_MESSAGE: `New chat message !!`,
    OFFER_SHOP: `New deals & offer !!`,
    PLAN_EXPIRED: `Subscription plan expired !!`,
    PLAN_EXPIRED_WARNING: `Subscription plan expiry warning !!`,
    FEEDBACK: `New feedback received !!`,
    ENQUIRY: `New enquiry received !!`,
  },
  notificationMessage: {
    NEW_ORDER: (name, orderNumber) =>
      `Hey ${name}! Your order #${orderNumber} is successfully placed. View your order details here`,
    NEW_ORDER_RECEIVED: (name) =>
      `You have received a new order from ${name}. Check here`,
    STATUS_ORDER: (name, orderNumber, status) =>
      `Hey ${name}! Your order #${orderNumber} is ${status}. View your order details here`,
    DELIVERY_ORDER: (name, orderNumber, status) =>
      `Hey ${name}! Your order #${orderNumber} is ready. Please visit the shop to collect`,
    REJECTED_ORDER: (name, orderNumber) =>
      `Hey ${name}! Your order #${orderNumber} is cancelled. Check reason here and provide feedback here`,
    NEW_PRODUCT_REQUEST: (number) =>
      `You have received a new product request #${number}`,
    STATUS_PRODUCT_REQUEST: (status) =>
      `Your product request has been ${status}. Check the status here.`,
    CHAT_MESSAGE_SEND: (name) => `You have a message from ${name}.`,
    OFFER_SHOP: () => `Hey, there's a new offer. Please check deals & offer`,
    PLAN_EXPIRED: `Your subscription plan is expired. Please renew it to enjoy services`,
    PLAN_EXPIRED_IN_DAYS: (day) =>
      `Your subscription plan is going to expired in ${day} days. Please renew it to enjoy services`,
    PLAN_EXPIRED_TODAY: `Your subscription plan is going to expired today. Please renew it to enjoy services`,
    FEEDBACK: `You have received a new feedback. View feedback in admin panel`,
    ENQUIRY: `You have received a new enquiry. View enquiry in admin panel`,
    PRODUCT_REQUEST: `You have received a new product request. View product request in admin panel`,
  },
  notificationType: {
    ORDER: "order",
    PRODUCT_REQUEST: "product_request",
    CHAT_MESSAGE: `chat_message`,
    PROMOTION: "promotion",
    SUBSCRIPTION_PLAN: `subscription_plan`,
    FEEDBACK: `feedback`,
    ENQUIRY: `enquiry`,
  },
  notificationType: {
    FOLLOW_USER: "follow_user",
    NEW_POST: "new_post",
  },
};
export default OPTIONS;
