import React from 'react'
import { Outlet } from 'react-router-dom'

const User = () => {
  return (
   
      
    <Outlet/>
  

  )
}

export default User