import { toast } from "react-toastify";
import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
const API_URL = environment.endPoint;

const cartItem = {
  async Create(payload) {
    try {
      const response = await axiosInstance.post(`/cartItem/create`, payload);
      const data=response.data.result
      toast.success(data.message)
      return data.data;
    } catch (error) {
      console.log(error);
      toast.error(error.message)
    }
  },
  async remove(id) {
    try {
      const response = await axiosInstance.delete(`/cartItem/remove/${id}`);
      let data=response.data.result
      toast.success(data.message);
    } catch (error) {
      console.log(error);
    }
  },
  async updateQuantity(id,payload) {
    try {
      const response = await axiosInstance.put(
        `/cartItem/updateQuantity/${id}`,
        payload
      );
     let data=response.data.result;
toast.success(data.message)
      return data
    } catch (error) {
      console.log(error);
      toast.error(error.message||"Internal Server Error");
    }
  },
};

export default cartItem;