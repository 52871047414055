import React from 'react'
import styles from "./itemCart.module.css";
import Constant from "../../../constant/Option"
import axios from 'axios';
import { toast } from 'react-toastify';
import CartService from '../../../services/cart.service';

const itemCart = ({imageUrl,title,_id,unit,measureUnit,price}) => {

const handleAddtoCart=async(_id)=>{
try {
  console.log("****product is added To Cart***",_id)
// const respone=await axios.post("http://localhost:4000/api/v1/website/cart/addToCart",{productId:_id,quantity:1})
const response=await CartService.addtoCart({productId:_id,quantity:1})
  
} catch (error) {
  toast.error(error.message||"Cannot Add to Cart")
}
}

  return ( 
   
        <div className="col-lg-3 col-md-4 col-sm-6  m-0  col-12 my-2 mx-md-0 ">
          <div className={styles.productCard}>
            <img
              src={imageUrl?imageUrl:Constant.defaultImage?.product}
              alt="Product"
              className={styles.productImg}
            />
            <h3 className={styles.productTitle}>
             
             {title}
            </h3>
            <div className={styles.productReviews}>
              <span className={styles.stars}>★★★★★</span>
              <span> 706 reviews</span>
            </div>
            <div className={styles.productPrice}>Rs. {price}</div>
            <div className={styles.productDropdown}>
              <span className='h5 mx-2'>{unit}</span>
             <span className='h5'>{measureUnit}</span>
             
            </div>
            <button className={`${styles.addToCartBtn} mt-3`} onClick={e=>handleAddtoCart(_id)}>ADD TO CART</button>
          </div>
       </div>
  )
}

export default itemCart