// src/components/ConfirmDialog.js

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

import { LoadingButton } from "@mui/lab";

const ConfirmDialog = ({ open, title, content, onClose, onConfirm,isloading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='contained' color="primary">
          No
        </Button>
        <LoadingButton
                            loading={isloading}  
                            type="submit"
                            variant="outlined"
                           color='error'
                           onClick={onConfirm}
                          >
                           YES
                          </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  title: 'Confirm Deletion',
  content: 'Are you sure you want to delete this item?',
};

export default ConfirmDialog;
