import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
import { toast } from "react-toastify";
const API_URL = environment.endPoint;

const user = [];

const User = {
  async getAllUser(payload) {
    try {
      const response = await axiosInstance.get(`/user/getAll`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async Create(payload) {
    try {
       const response = await axiosInstance.post(`/user/register`,payload);
       const data=response.data.result
       toast.success(data.message);
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async getById(payload) {
    try {
       const response = await axiosInstance.get(`/user/getById/${payload}`);
    return response.data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async updateById(payload,id) {
    try {
       const response = await axiosInstance.put(`/user/updateById/${id}`,payload);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async delete(id) {
    try {
       const response = await axiosInstance.delete(`/user/delete/${id}`);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },
};

export default User;
