
import apiRequest from "../Component/customHool/axiosRequest";

const Product = {
  async getAll(params) {
    return apiRequest('GET', '/product/getAll', {}, params);
  },

  async Create(payload) {
    return apiRequest('POST', '/product/create', payload, {});
  },

  async getById(id) {
    return apiRequest('GET', `/product/getById/${id}`, {}, {});
  },

  async updateById(payload, id) {
    return apiRequest('PUT', `/product/update/${id}`, payload, {});
  },

  async delete(id) {
    return apiRequest('DELETE', `/product/delete/${id}`, {}, {});
  }
};

export default Product;
