import { createSlice } from "@reduxjs/toolkit";

var initialState = {
  Astrologers: [],
  Cart: false,
  currentUser: {},
  User: [],
  Product: [],
  Order: [],
  Payment: [],
  Visit: [],
  Invoice: [],
  About: {},
  Services: [{ heading: "shakir" }],
  Questions: [],
  Blogs: [],
  Notifications: [{ heading: "shakir" }],
  Form:{
    isOpen: false,
    fields: [],
    formData: {},
    onSubmit: null,
    formInfo:{}
  }
};

export const AstroSlice = createSlice({
  name: "Astrology",
  initialState,
  reducers: {
    addBulkUser: (state, action) => {
      state.User = action.payload;
    },
    addUser: (state, action) => {
      state.User.push(action.payload);
    },
    removeUser: (state, action) => {
      state.User = state.User.filter((astro) => astro._id !== action.payload);
    },
    updateUser: (state, action) => {
      state.User = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addBulkProduct: (state, action) => {
      state.Product = action.payload;
    },
    addProduct: (state, action) => {
      state.Product.push(action.payload);
    },
    removeProduct: (state, action) => {
      state.Product = state.User.filter(
        (astro) => astro._id !== action.payload
      );
    },
    updateProduct: (state, action) => {
      state.Product = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addBulkOrder: (state, action) => {
      state.Order = action.payload;
    },
    addOrder: (state, action) => {
      state.Order.push(action.payload);
    },
    removeOrder: (state, action) => {
      state.Order = state.User.filter((astro) => astro._id !== action.payload);
    },
    updateOrder: (state, action) => {
      state.Order = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addBulkPayment: (state, action) => {
      state.Payment = action.payload;
    },
    addPayment: (state, action) => {
      state.Payment.push(action.payload);
    },
    removePayment: (state, action) => {
      state.Payment = state.User.filter(
        (astro) => astro._id !== action.payload
      );
    },
    updatePayment: (state, action) => {
      state.Payment = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addBulkInvoice: (state, action) => {
      state.Invoice = action.payload;
    },
    addInvoice: (state, action) => {
      state.Invoice.push(action.payload);
    },
    removeInvoice: (state, action) => {
      state.Invoice = state.User.filter(
        (astro) => astro._id !== action.payload
      );
    },
    updateInvoice: (state, action) => {
      state.Invoice = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addBulkVisit: (state, action) => {
      console.log("you are called the addBuilVvisit", action.payload);
      state.Visit = action.payload;
    },
    addVisit: (state, action) => {
      state.Visit.push(action.payload);
    },
    removeVisit: (state, action) => {
      state.Visit = state.User.filter((astro) => astro._id !== action.payload);
    },
    updateVisit: (state, action) => {
      state.Visit = state.User.map((astro) =>
        astro._id === action.payload._id ? action.payload : astro
      );
    },

    addAbout: (state, action) => {
      state.About = action.payload;
    },

    updateAbout: (state, action) => {
      state.About = action.payload;
    },

    addBulkQuestions: (state, action) => {
      state.Questions = [...action.payload];
    },
    addQuestions: (state, action) => {
      state.Questions.push(action.payload);
    },
    removeQuestions: (state, action) => {
      state.Questions = state.Questions.filter(
        (question) => question._id !== action.payload
      );
    },
    updateQuestions: (state, action) => {
      state.Questions = state.Questions.map((question) =>
        question._id === action.payload._id ? action.payload : question
      );
    },

    addBulkServices: (state, action) => {
      state.Services = [...action.payload];
    },
    addServices: (state, action) => {
      state.Services.push(action.payload);
    },
    removeServices: (state, action) => {
      state.Services = state.Services.filter(
        (question) => question._id !== action.payload
      );
    },
    updateServices: (state, action) => {
      state.Services = state.Services.map((question) =>
        question._id === action.payload._id ? action.payload : question
      );
    },

    addBulkBlogs: (state, action) => {
      state.Blogs = [...action.payload];
    },
    addBlogs: (state, action) => {
      state.Blogs.push(action.payload);
    },
    removeBlogs: (state, action) => {
      state.Blogs = state.Blogs.filter(
        (question) => question._id !== action.payload
      );
    },
    updateBlogs: (state, action) => {
      state.Blogs = state.Blogs.map((question) =>
        question._id === action.payload._id ? action.payload : question
      );
    },

    addBulkNotifications: (state, action) => {
      state.Notifications = [...action.payload];
    },
    addNotifications: (state, action) => {
      state.Notifications.push(action.payload);
    },
    removeNotifications: (state, action) => {
      state.Notifications = state.Notifications.filter(
        (question) => question._id !== action.payload
      );
    },
    updateNotifications: (state, action) => {
      state.Notifications = state.Notifications.map((question) =>
        question._id === action.payload._id ? action.payload : question
      );
    },
    addCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },

    openCart: (state, action) => {
      console.log("I am called to open");
      state.Cart = true;
    },

    closeCart: (state, action) => {
      state.Cart = false;
    },

    openForm: (state, action) => {
      state.Form.isOpen = true;
      state.Form.fields = action.payload.fields;
      state.Form.formInfo=action.payload.formInfo
      state.Form.onSubmit = action.payload.onSubmit;
      state.Form.formData = {}; // Reset form data when opening
    },

    setFormData: (state, action) => {
      const { field, value } = action.payload;
      state.Form.formData[field] = value;
    },

    closeForm: (state) => {
      state.Form.isOpen = false;
      state.Form.fields = [];
      state.Form.onSubmit = null;
      state.Form.formData = {};
      state.Form.formInfo = {};
    },
  },
});

export const {
  addAbout,
  updateAbout,
  addBulkQuestions,
  addQuestions,
  removeQuestions,
  updateQuestions,
  addBulkServices,
  addServices,
  updateServices,
  removeServices,
  addBulkBlogs,
  addBlogs,
  removeBlogs,
  updateBlogs,
  addBulkNotifications,
  addNotifications,
  removeNotifications,
  updateNotifications,
  updateUser,
  removeUser,
  addUser,
  addBulkUser,

  updateProduct,
  removeProduct,
  addProduct,
  addBulkProduct,

  addBulkOrder,
  removeOrder,
  updateOrder,
  addOrder,

  updatePayment,
  removePayment,
  addPayment,
  addBulkPayment,

  updateInvoice,
  removeInvoice,
  addInvoice,
  addBulkInvoice,

  updateVisit,
  removeVisit,
  addVisit,
  addBulkVisit,

  addCurrentUser,

  closeCart,
  openCart,

  openForm,
  closeForm,
  setFormData,
} = AstroSlice.actions;
export default AstroSlice.reducer;
