import React, { useEffect, useState } from "react";
import ItemCart from "./itemCart";
import Product from "../../../services/product.service"
const Items = () => {
const [product,setProduct]=useState([]);
const [filter,setFilter]=useState({})

async function fetchProducts() {
   const response=await Product.getAll(filter)
   setProduct(response.data);
} 


useEffect(()=>{
  fetchProducts();
},[]);

  return (
    <div className="row d-flex  m-0 ">
      {product
        .map((item) => {
        return  <ItemCart imageUrl={item.imageUrl} title={item.title} _id={item._id} unit={item.unit} measureUnit={item.measureUnit} price={item.price}/>;
        })}
    </div>
  );
};

export default Items;
