
import apiRequest from "../Component/customHool/axiosRequest";

const Location = {
//   async updateById(payload, id) {
//     try {
//       const response = await axiosInstance.put(`/Location/update/${id}`, payload);
//       let data = response.data.result;
//       toast.success(data.message);
//       return response.data;
//     } catch (error) {
//       toast.error(
//         error.response.data.message
//           ? error.response.data.message
//           : "Internal Server Error"
//       );
//       console.log(error);
//     }
//   },

  async getOnlineUser(params) {
    // try {
    //   const response = await axiosInstance.get(`/location/getOnlineUser/`, {
    //     params: params,
    //   });
    //   let data = response.data.result;
    //   toast.success(data.message);
    //   return data;
    // } catch (error) {
    //   toast.error(
    //     error.response.data.message
    //       ? error.response.data.message
    //       : "Internal Server Error"
    //   );
    //   console.log(error);
    // }
    return apiRequest('GET','/location/getOnlineUser/',{},params)
  },

  
};

export default Location;
