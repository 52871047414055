import { toast } from "react-toastify";

const toTitleCase = (str) => {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back together with spaces
};
const Helper = {
  isFormValid: (formData, validateArray) => {
    console.log("come to validate");
    const requiredFields = validateArray.map((item) => {
      let label = toTitleCase(item.replace(/([a-z])([A-Z])/g, "$1 $2"));
      return {
        value: formData[item],
        message: `${label} field should not be empty`,
      };
    });

    console.log("came to validate", requiredFields);
    const errorMessages = requiredFields
      .filter((field) => !field.value) // Filter out fields that are empty
      .map((field) => field.message);

    errorMessages.forEach((err, index) => {
      toast.error(err);
    });
    if (errorMessages.length > 0) {
      return false;
    }
    return true;
  },





  isFormValid2: (formData, validateArray) => {
    const requiredFields = validateArray.map((item) => {
      let label = toTitleCase(item.fieldName.replace(/([a-z])([A-Z])/g, "$1 $2"));

      // Default error message if the field is required but empty
      let errorMessage = `${label} field should not be empty`;

      // Check if the field is required and empty
      if (item.rules?.isRequired && !formData[item.fieldName]) {
        errorMessage = `${label} is required`;
      }

      // Check for positive number validation
      if (item.rules?.isPositive && formData[item.fieldName] <= 0) {
        errorMessage = `${label} must be a positive number`;
      }

      // Check for valid email format
      if (item.rules?.isEmail && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData[item.fieldName])) {
        errorMessage = `Please enter a valid ${label}`;
      }

      // Check for phone number validation (10 digits)
      if (item.rules?.isPhoneNumber && !/^\d{10}$/.test(formData[item.fieldName])) {
        errorMessage = `${label} must be a 10-digit number`;
      }

      return {
        value: formData[item.fieldName],
        message: errorMessage,
        rules: item.rules,
      };
    });

    // Filter and display error messages for invalid fields
    const errorMessages = requiredFields
      .filter(
        (field) =>
          (field.rules?.isRequired && !field.value) || // Required fields empty
          (field.rules?.isPositive && field.value <= 0) || // Positive number validation
          (field.rules?.isEmail && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(field.value)) || // Email validation
          (field.rules?.isPhoneNumber && !/^\d{10}$/.test(field.value)) // Phone number validation
      )
      .map((field) => field.message);

    // Show error messages using toast
    errorMessages.forEach((err) => {
      toast.error(err);
    });

    if (errorMessages.length > 0) {
      return false;
    }
    return true;
  },




























};
export default Helper;



