import React, { useCallback } from "react";
import "./mapview.css";
import { Fragment, useEffect, useState } from "react";
import { useSocket } from "../../../useSocket.js/socket";
import Visit from "../../../services/visit.service";
import CONSTANT from "../../../constant/Option";
import Location from "../../../services/location";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Socket } from "socket.io-client";
import { useSelector } from "react-redux";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const markers = [
  {
    id: 1,
    name: "Amit",
    position: { lat: 21.171281, lng: 79.134078 },
  },
  {
    id: 2,
    name: "opendra",
    position: { lat: 21.171945, lng: 79.1340871 },
  },
  {
    id: 3,
    name: "Anand",
    position: { lat: 21.1798439, lng: 79.1351881 },
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const calculateDistance = (coord1, coord2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371e3; // Earth radius in meters

  const lat1 = toRad(coord1.lat);
  const lat2 = toRad(coord2.lat);
  const deltaLat = toRad(coord2.lat - coord1.lat);
  const deltaLng = toRad(coord2.lng - coord1.lng);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};

function Create() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDriXORK00W-RReAC-vGwoDt-2-e0EaoXg",
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [filter, setFilter] = useState({
    startDate: dayjs().subtract(1, "day"), // One day before today
    endDate: dayjs().add(1, "day"),
    typeOfVisit: [],
  });

  const currentUser=useSelector(state=>state.currentUser)
  const [mapCenter, setMapCenter] = useState({ lat: 21.1717469, lng: 79.1340581 }); // Initial map center
  const thresholdDistance = 50; // Define threshold in meters



 

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setFilter({
      ...filter,
      typeOfVisit: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // const fetchMarkers = async () => {
  //   try {
  //     const data = await Visit.getAllForMap(filter);
  //     console.log("got the data from api", data.data);
  //     setMarkers(data.data); // Update state with fetched marker data
  //   } catch (error) {
  //     console.error("Error fetching marker data:", error);
  //   }
  // };

  // useEffect(() => {
  //   // Fetch marker data from your API

  //   fetchMarkers();
  // }, []);

  // useEffect(() => {
  //   fetchMarkers();
  // }, [filter]);

  const infoWindowStyle = {
    padding: "10px",
    maxWidth: "200px",
    textAlign: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
  };

  const imageStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "10px",
  };

  const headerStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "5px 0",
  };

  const typeOfVisitStyle = {
    fontSize: "14px",
    color: "#555",
  };

  const timestampStyle = {
    fontSize: "12px",
    color: "blue", // Gray color for subtlety
    marginTop: "5px",
  };

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]); // State to handle selected users
  const socket=useSocket();

  // Fetch the list of users
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        // Make the API call to fetch users
        // const response = await axios.get('/api/users'); // Replace with your API endpoint
        const data = await Location.getOnlineUser({});
        const users = [...data.data];

        console.log("*** make the api Call ***");

        // Only update the state if the component is still mounted
        if (active) {
          setOptions(users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false; // Cleanup
    };
  }, [loading]);

  useEffect(() => {
    console.log("Actual selected User", selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    console.log("selected user", selectedUsers);
   
    if (open) {
      setLoading(true);
    }
  }, [open]);




  const handleUserLocation=useCallback(({name,_id,roomId,date,position,imageUrl})=>{

    setMarkers((prevMarkers) => {
      const existingMarkerIndex = prevMarkers.findIndex(marker => marker._id === _id);
      const formattedDate = new Date(date).toLocaleString();
      console.log("###updated Marker####",date);
      if (existingMarkerIndex !== -1) {
        // If marker exists, update its position
        const updatedMarkers = [...prevMarkers];
        updatedMarkers[existingMarkerIndex].position=position;
        updatedMarkers[existingMarkerIndex].date = formattedDate; // Update date
        return updatedMarkers;
      } else {
        // If marker doesn't exist, create a new marker
        const newMarker = {
          name,
          _id,
          roomId,
          date:formattedDate,
          position,
          imageUrl,

        }

        return [
          ...prevMarkers,
         newMarker
        ];
      }

    });
    const distanceFromCenter = calculateDistance(mapCenter, position);
    console.log("*************")
    if (distanceFromCenter > thresholdDistance) {
      // Update map center if the new marker is far enough from the current center
      setMapCenter(position);
    }

    console.log("****Marker****",markers);

  },[mapCenter])


  useEffect(()=>{
  socket.on("handle:user:location",handleUserLocation)
  return ()=>{
    socket.off("handle:user:location",handleUserLocation)
    socket.emit("admin:exit:location:room",{roomId:currentUser._id});
  }
  },[])


const handleAutoClose=()=>{
  socket.emit("online:user:location",{
    selectedUsers:selectedUsers,
    roomId:currentUser._id
   })
  setOpen(false)
}

  return (
    <Fragment>
      <div
        className="container"
        style={{ overflow: "visible" }}
        id="map-container"
      >
        <div className="row my-2 m-0 p-0">
          <div className="col-12 col-md-12 my-2 my-sm-2 ps-1 p-0">
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={options}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => handleAutoClose()}
              disableCloseOnSelect
              loading={loading}
              value={selectedUsers} // Track selected users
              onChange={(event, newValue) => {
                // Handle selection/deselection
                const filteredValues = newValue.filter(
                  (option) =>
                    selectedUsers.includes(option) ||
                    !selectedUsers.includes(option)
                );
                setSelectedUsers(filteredValues); // Update the selected users with filtered values
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => {
                // Check if the option is already selected by comparing it to the selectedUsers array
                const isSelected = selectedUsers.some(
                  (selectedUser) => selectedUser.name === option.name
                );

                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected} // Use isSelected instead of selected
                    />
                    {option.name}
                  </li>
                );
              }}
              style={{ width: "80%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Users"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        <div style={{ height: "75vh", width: "100%" }}>
          {isLoaded ? (
            <GoogleMap
              center={mapCenter}
              zoom={18}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "90vh" }}
            >
              {markers?.map(
                ({ _id, name, position, typeOfVisit, date,imageUrl }) => {
                  // Ensure latitude and longitude are numbers

                  const isValidPosition =
                    !isNaN(position.lat) && !isNaN(position.lng);

                  return isValidPosition ? (
                    <MarkerF
                      key={_id}
                      position={position}
                      onClick={() => handleActiveMarker(_id)}
                      title="I am marker"
                      zIndex={1}
                      draggable={false}
                      icon={{
                        url: imageUrl
                          ? imageUrl
                          : "https://res.cloudinary.com/dvyt63pnb/image/upload/v1727154633/q2ykz6vkcc7qqmgr4a5i.png",
                        scaledSize: { width: 50, height: 50 },
                      }}
                      // label={{
                      //   text: "Marker Label", // The text for the label
                      //   color: "red", // Text color
                      //   fontSize: "14px", // Font size
                      //   fontWeight: "bold",
                      // }}
                    >
                      {activeMarker === _id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div style={infoWindowStyle}>
                            <img
                              src={imageUrl?imageUrl:"https://res.cloudinary.com/dvyt63pnb/image/upload/v1727154633/q2ykz6vkcc7qqmgr4a5i.png"}
                              alt={`${name}'s profile`}
                              style={imageStyle}
                            />
                            <h4 style={headerStyle}>
                              {name?name:null}
                            </h4>
                            <p style={typeOfVisitStyle}>{typeOfVisit}</p>
                            <p style={timestampStyle}>
                              Active at: {date}
                            </p>
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </MarkerF>
                  ) : null;
                }
              )}
            </GoogleMap>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default Create;
